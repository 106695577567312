<template>
  <div>

    <section class="blade search-input-section has-text-centered">

      <div class="search-input-content is-flex is-align-items-center is-flex is-justify-content-center">
        <div class="content">
          <img class="logo mb-2" src="/images/freesearch-logo.png" alt="Freesearch">
          <search-input class="is-block search-input" />
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";

export default {
  name: "Home",
  components: { SearchInput },
  data:() => ({})
};
</script>

<style lang="scss" scoped>


.search-input-section{
  height: 100vh;
  min-height: 660px;
  position: relative;
}

.ceph-logo{
  z-index: 2;
  top: 40px;
  position: relative;
  @media (hover: hover) {
    position: absolute;
    left: 40px;
  }
}

.search-input-content{
  z-index: 2;
  height:100%;
  position: relative;
  @media not all and (hover: hover) {
    bottom: 60px;
  }
  & > .content{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    h1 {
      font-weight: 800;
      font-size: 1.5em;
    }
    h2 {
      font-weight: 800;
      font-size: 1.5em;
    }
    p {
      font-size: 17px;
      margin-top: 2.8em;
    }

    @media not all and (hover: hover) and (min-device-height: 700px){
      h1 {
        font-weight: 600;
        font-size: 1.1em;
        margin-bottom: 1px;
      }
      h2 {
        font-weight: 600;
        font-size: 1.1em;
        margin-bottom: 1px;
      }
      p {
        font-size: 10px;
        margin-top: 1em;
      }
    }
  }
}

.search-input {
  width: 100%;
  max-width: 560px;
  margin: 25px auto 50px;

  @media not all and (hover: hover) and (min-device-height: 700px){
    margin-bottom: 30px;
  }
}

.more-arrow-container{
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  &:hover .more-arrow{
    transform: scale(1.5);
  }
}
</style>
