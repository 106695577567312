<template>
  <div id="app">
    <top-bar v-if="!isHomePage" />
    <site-menu v-else class="top-site-menu" />
    <router-view style="min-height: 800px" />
    <site-footer />

  </div>
</template>


<script>
import TopBar from '@/components/TopBar.vue';
import SiteMenu from '@/components/SiteMenu.vue';
import SiteFooter from '@/components/SiteFooter.vue';


export default {
  components: {
    TopBar, SiteMenu, SiteFooter,
  },
  computed: {
    isHomePage(){
      return this.$route.name === 'Home'
    }
  },
  data:() => ({
    showFeedback: false,
    showJoinUs: false,
    siteName: process.env.VUE_APP_SITE_NAME,
  }),
  mounted(){
    this.$eventBus.$on('scrollToTop', () => {
      this.$scrollTo('body');
    });
    this.$eventBus.$on('showFeedback', () => {
      this.showFeedback = true;
    });
    this.$eventBus.$on('showJoinUs', () => {
      this.showJoinUs = true;
    });

    if(window.location.href.indexOf('form=feedback') > -1){
      this.showFeedback = true;
    }
    if(window.location.href.indexOf('form=join-us') > -1){
      this.showJoinUs = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.top-site-menu{
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}
@media screen and (max-width: 768px){
  .top-site-menu{
    top: 20px;
    right: 20px;
  }
}
</style>